<template>
  <v-select
    messages="true"
    :items="availableTimeZones"
    item-text="name"
    item-value="name"
    :value="timeZone"
    :label="localizedTimeZone"
    menu-props="auto"
    data-cy="time-zone-selector"
    @input="selectTimeZone"
  >
    <template #message>
      <v-alert
        color="info"
        dense
        icon="mdi-information"
        class="core-element-info-message"
        outlined
      >
        <small v-t="'tips.productDetails.soThatWeKnowWhenToTryToReachYou'" />
      </v-alert>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    timeZone: {
      type: String,
      default: ''
    },
    setTimeZone: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      availableTimeZones: [
        {
          GMTOffset: 'GMT-03:00',
          cities: ['Brasilia'],
          name: '(GMT-03:00) Brasilia'
        },
        {
          GMTOffset: 'GMT-12:00',
          cities: ['International Date Line West'],
          name: '(GMT-12:00) International Date Line West'
        },
        {
          GMTOffset: 'GMT-11:00',
          cities: ['Midway Island', 'Samoa'],
          name: '(GMT-11:00) Midway Island, Samoa'
        },
        {
          GMTOffset: 'GMT-10:00',
          cities: ['Hawaii'],
          name: '(GMT-10:00) Hawaii'
        },
        {
          GMTOffset: 'GMT-09:00',
          cities: ['Alaska'],
          name: '(GMT-09:00) Alaska'
        },
        {
          GMTOffset: 'GMT-08:00',
          cities: ['Pacific Time (US & Canada)'],
          name: '(GMT-08:00) Pacific Time (US & Canada)'
        },
        {
          GMTOffset: 'GMT-08:00',
          cities: ['Tijuana', 'Baja California'],
          name: '(GMT-08:00) Tijuana, Baja California'
        },
        {
          GMTOffset: 'GMT-07:00',
          cities: ['Arizona'],
          name: '(GMT-07:00) Arizona'
        },
        {
          GMTOffset: 'GMT-07:00',
          cities: ['Chihuahua', 'La Paz', 'Mazatlan'],
          name: '(GMT-07:00) Chihuahua, La Paz, Mazatlan'
        },
        {
          GMTOffset: 'GMT-07:00',
          cities: ['Mountain Time (US & Canada)'],
          name: '(GMT-07:00) Mountain Time (US & Canada)'
        },
        {
          GMTOffset: 'GMT-06:00',
          cities: ['Central America'],
          name: '(GMT-06:00) Central America'
        },
        {
          GMTOffset: 'GMT-06:00',
          cities: ['Central Time (US & Canada)'],
          name: '(GMT-06:00) Central Time (US & Canada)'
        },
        {
          GMTOffset: 'GMT-06:00',
          cities: ['Guadalajara', 'Mexico City', 'Monterrey'],
          name: '(GMT-06:00) Guadalajara, Mexico City, Monterrey'
        },
        {
          GMTOffset: 'GMT-06:00',
          cities: ['Saskatchewan'],
          name: '(GMT-06:00) Saskatchewan'
        },
        {
          GMTOffset: 'GMT-05:00',
          cities: ['Bogota', 'Lima', 'Quito', 'Rio Branco'],
          name: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco'
        },
        {
          GMTOffset: 'GMT-05:00',
          cities: ['Eastern Time (US & Canada)'],
          name: '(GMT-05:00) Eastern Time (US & Canada)'
        },
        {
          GMTOffset: 'GMT-05:00',
          cities: ['Indiana (East)'],
          name: '(GMT-05:00) Indiana (East)'
        },
        {
          GMTOffset: 'GMT-04:00',
          cities: ['Atlantic Time (Canada)'],
          name: '(GMT-04:00) Atlantic Time (Canada)'
        },
        {
          GMTOffset: 'GMT-04:00',
          cities: ['Caracas', 'La Paz'],
          name: '(GMT-04:00) Caracas, La Paz'
        },
        {
          GMTOffset: 'GMT-04:00',
          cities: ['Manaus'],
          name: '(GMT-04:00) Manaus'
        },
        {
          GMTOffset: 'GMT-04:00',
          cities: ['Santiago'],
          name: '(GMT-04:00) Santiago'
        },
        {
          GMTOffset: 'GMT-03:30',
          cities: ['Newfoundland'],
          name: '(GMT-03:30) Newfoundland'
        },
        {
          GMTOffset: 'GMT-03:00',
          cities: ['Brasilia'],
          name: '(GMT-03:00) Brasilia'
        },
        {
          GMTOffset: 'GMT-03:00',
          cities: ['Buenos Aires', 'Georgetown'],
          name: '(GMT-03:00) Buenos Aires, Georgetown'
        },
        {
          GMTOffset: 'GMT-03:00',
          cities: ['Greenland'],
          name: '(GMT-03:00) Greenland'
        },
        {
          GMTOffset: 'GMT-03:00',
          cities: ['Montevideo'],
          name: '(GMT-03:00) Montevideo'
        },
        {
          GMTOffset: 'GMT-02:00',
          cities: ['Mid-Atlantic'],
          name: '(GMT-02:00) Mid-Atlantic'
        },
        {
          GMTOffset: 'GMT-01:00',
          cities: ['Cape Verde Is.'],
          name: '(GMT-01:00) Cape Verde Is.'
        },
        {
          GMTOffset: 'GMT-01:00',
          cities: ['Azores'],
          name: '(GMT-01:00) Azores'
        },
        {
          GMTOffset: 'GMT+00:00',
          cities: ['Casablanca', 'Monrovia', 'Reykjavik'],
          name: '(GMT+00:00) Casablanca, Monrovia, Reykjavik'
        },
        {
          GMTOffset: 'GMT+00:00',
          cities: [
            'Greenwich Mean Time : Dublin',
            'Edinburgh',
            'Lisbon',
            'London'
          ],
          name:
                        '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London'
        },
        {
          GMTOffset: 'GMT+01:00',
          cities: [
            'Amsterdam',
            'Berlin',
            'Bern',
            'Rome',
            'Stockholm',
            'Vienna'
          ],
          name:
                        '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
        },
        {
          GMTOffset: 'GMT+01:00',
          cities: [
            'Belgrade',
            'Bratislava',
            'Budapest',
            'Ljubljana',
            'Prague'
          ],
          name:
                        '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
        },
        {
          GMTOffset: 'GMT+01:00',
          cities: ['Brussels', 'Copenhagen', 'Madrid', 'Paris'],
          name: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris'
        },
        {
          GMTOffset: 'GMT+01:00',
          cities: ['Sarajevo', 'Skopje', 'Warsaw', 'Zagreb'],
          name: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb'
        },
        {
          GMTOffset: 'GMT+01:00',
          cities: ['West Central Africa'],
          name: '(GMT+01:00) West Central Africa'
        },
        {
          GMTOffset: 'GMT+02:00',
          cities: ['Amman'],
          name: '(GMT+02:00) Amman'
        },
        {
          GMTOffset: 'GMT+02:00',
          cities: ['Athens', 'Bucharest', 'Istanbul'],
          name: '(GMT+02:00) Athens, Bucharest, Istanbul'
        },
        {
          GMTOffset: 'GMT+02:00',
          cities: ['Beirut'],
          name: '(GMT+02:00) Beirut'
        },
        {
          GMTOffset: 'GMT+02:00',
          cities: ['Cairo'],
          name: '(GMT+02:00) Cairo'
        },
        {
          GMTOffset: 'GMT+02:00',
          cities: ['Harare', 'Pretoria'],
          name: '(GMT+02:00) Harare, Pretoria'
        },
        {
          GMTOffset: 'GMT+02:00',
          cities: [
            'Helsinki',
            'Kyiv',
            'Riga',
            'Sofia',
            'Tallinn',
            'Vilnius'
          ],
          name:
                        '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'
        },
        {
          GMTOffset: 'GMT+02:00',
          cities: ['Jerusalem'],
          name: '(GMT+02:00) Jerusalem'
        },
        {
          GMTOffset: 'GMT+02:00',
          cities: ['Minsk'],
          name: '(GMT+02:00) Minsk'
        },
        {
          GMTOffset: 'GMT+02:00',
          cities: ['Windhoek'],
          name: '(GMT+02:00) Windhoek'
        },
        {
          GMTOffset: 'GMT+03:00',
          cities: ['Kuwait', 'Riyadh', 'Baghdad'],
          name: '(GMT+03:00) Kuwait, Riyadh, Baghdad'
        },
        {
          GMTOffset: 'GMT+03:00',
          cities: ['Moscow', 'St. Petersburg', 'Volgograd'],
          name: '(GMT+03:00) Moscow, St. Petersburg, Volgograd'
        },
        {
          GMTOffset: 'GMT+03:00',
          cities: ['Nairobi'],
          name: '(GMT+03:00) Nairobi'
        },
        {
          GMTOffset: 'GMT+03:00',
          cities: ['Tbilisi'],
          name: '(GMT+03:00) Tbilisi'
        },
        {
          GMTOffset: 'GMT+03:30',
          cities: ['Tehran'],
          name: '(GMT+03:30) Tehran'
        },
        {
          GMTOffset: 'GMT+04:00',
          cities: ['Abu Dhabi', 'Muscat'],
          name: '(GMT+04:00) Abu Dhabi, Muscat'
        },
        {
          GMTOffset: 'GMT+04:00',
          cities: ['Baku'],
          name: '(GMT+04:00) Baku'
        },
        {
          GMTOffset: 'GMT+04:00',
          cities: ['Yerevan'],
          name: '(GMT+04:00) Yerevan'
        },
        {
          GMTOffset: 'GMT+04:30',
          cities: ['Kabul'],
          name: '(GMT+04:30) Kabul'
        },
        {
          GMTOffset: 'GMT+05:00',
          cities: ['Yekaterinburg'],
          name: '(GMT+05:00) Yekaterinburg'
        },
        {
          GMTOffset: 'GMT+05:00',
          cities: ['Islamabad', 'Karachi', 'Tashkent'],
          name: '(GMT+05:00) Islamabad, Karachi, Tashkent'
        },
        {
          GMTOffset: 'GMT+05:30',
          cities: ['Sri Jayawardenapura'],
          name: '(GMT+05:30) Sri Jayawardenapura'
        },
        {
          GMTOffset: 'GMT+05:30',
          cities: ['Chennai', 'Kolkata', 'Mumbai', 'New Delhi'],
          name: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi'
        },
        {
          GMTOffset: 'GMT+05:45',
          cities: ['Kathmandu'],
          name: '(GMT+05:45) Kathmandu'
        },
        {
          GMTOffset: 'GMT+06:00',
          cities: ['Almaty', 'Novosibirsk'],
          name: '(GMT+06:00) Almaty, Novosibirsk'
        },
        {
          GMTOffset: 'GMT+06:00',
          cities: ['Astana', 'Dhaka'],
          name: '(GMT+06:00) Astana, Dhaka'
        },
        {
          GMTOffset: 'GMT+06:30',
          cities: ['Yangon (Rangoon)'],
          name: '(GMT+06:30) Yangon (Rangoon)'
        },
        {
          GMTOffset: 'GMT+07:00',
          cities: ['Bangkok', 'Hanoi', 'Jakarta'],
          name: '(GMT+07:00) Bangkok, Hanoi, Jakarta'
        },
        {
          GMTOffset: 'GMT+07:00',
          cities: ['Krasnoyarsk'],
          name: '(GMT+07:00) Krasnoyarsk'
        },
        {
          GMTOffset: 'GMT+08:00',
          cities: ['Beijing', 'Chongqing', 'Hong Kong', 'Urumqi'],
          name: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi'
        },
        {
          GMTOffset: 'GMT+08:00',
          cities: ['Kuala Lumpur', 'Singapore'],
          name: '(GMT+08:00) Kuala Lumpur, Singapore'
        },
        {
          GMTOffset: 'GMT+08:00',
          cities: ['Irkutsk', 'Ulaan Bataar'],
          name: '(GMT+08:00) Irkutsk, Ulaan Bataar'
        },
        {
          GMTOffset: 'GMT+08:00',
          cities: ['Perth'],
          name: '(GMT+08:00) Perth'
        },
        {
          GMTOffset: 'GMT+08:00',
          cities: ['Taipei'],
          name: '(GMT+08:00) Taipei'
        },
        {
          GMTOffset: 'GMT+09:00',
          cities: ['Osaka', 'Sapporo', 'Tokyo'],
          name: '(GMT+09:00) Osaka, Sapporo, Tokyo'
        },
        {
          GMTOffset: 'GMT+09:00',
          cities: ['Seoul'],
          name: '(GMT+09:00) Seoul'
        },
        {
          GMTOffset: 'GMT+09:00',
          cities: ['Yakutsk'],
          name: '(GMT+09:00) Yakutsk'
        },
        {
          GMTOffset: 'GMT+09:30',
          cities: ['Adelaide'],
          name: '(GMT+09:30) Adelaide'
        },
        {
          GMTOffset: 'GMT+09:30',
          cities: ['Darwin'],
          name: '(GMT+09:30) Darwin'
        },
        {
          GMTOffset: 'GMT+10:00',
          cities: ['Brisbane'],
          name: '(GMT+10:00) Brisbane'
        },
        {
          GMTOffset: 'GMT+10:00',
          cities: ['Canberra', 'Melbourne', 'Sydney'],
          name: '(GMT+10:00) Canberra, Melbourne, Sydney'
        },
        {
          GMTOffset: 'GMT+10:00',
          cities: ['Hobart'],
          name: '(GMT+10:00) Hobart'
        },
        {
          GMTOffset: 'GMT+10:00',
          cities: ['Guam', 'Port Moresby'],
          name: '(GMT+10:00) Guam, Port Moresby'
        },
        {
          GMTOffset: 'GMT+10:00',
          cities: ['Vladivostok'],
          name: '(GMT+10:00) Vladivostok'
        },
        {
          GMTOffset: 'GMT+11:00',
          cities: ['Magadan', 'Solomon Is.', 'New Caledonia'],
          name: '(GMT+11:00) Magadan, Solomon Is., New Caledonia'
        },
        {
          GMTOffset: 'GMT+12:00',
          cities: ['Auckland', 'Wellington'],
          name: '(GMT+12:00) Auckland, Wellington'
        },
        {
          GMTOffset: 'GMT+12:00',
          cities: ['Fiji', 'Kamchatka', 'Marshall Is.'],
          name: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.'
        },
        {
          GMTOffset: 'GMT+13:00',
          cities: ["Nuku'alofa"],
          name: "(GMT+13:00) Nuku'alofa"
        }
      ]
    }
  },
  computed: {
    localizedTimeZone () {
      return this.$t('support.timeZone')
    }
  },
  updated () {
    this.selectDefaultTimeZone()
  },
  created () {
    this.selectDefaultTimeZone()
  },
  methods: {
    selectTimeZone (payload) {
      this.setTimeZone(payload)
    },
    selectDefaultTimeZone () {
      if (this.timeZone === '') {
        if (!this.tryToSelectByCity()) {
          if (!this.tryToSelectByGMTOffset()) {
            this.setTimeZone('(GMT-03:00) Brasilia')
          }
        }
      }
    },
    tryToSelectByCity () {
      const city = Intl.DateTimeFormat()
        .resolvedOptions()
        .timeZone.match(/\/(.*)/)
      if (city) {
        const timeZone = this.availableTimeZones.filter(zone =>
          zone.cities.includes(city[1])
        )
        if (timeZone) {
          const timeZoneName = timeZone[0]
          if (timeZoneName.name) {
            this.setTimeZone(timeZoneName.name)
            return true
          }
        }
      }
      return false
    },
    tryToSelectByGMTOffset () {
      const gmtOffset = new Date().toString().match(/([A-Z]+[+-][0-9]*)/)
      if (gmtOffset) {
        const gmtOffsetName =
            `${gmtOffset[1].slice(0, -2) }:${ gmtOffset[1].slice(-2)}`

        const timeZone = this.availableTimeZones.find(
          zone => zone.GMTOffset === gmtOffsetName
        )
        if (timeZone && timeZone.name) {
          this.setTimeZone(timeZone.name)
          return true
        }
      }
      return false
    }
  }
}
</script>
